import * as React from "react"
import { Container, Carousel } from "react-bootstrap" 
import { StaticImage } from "gatsby-plugin-image"

function OurBlog() {
	return (
        <div className="blog">
            <div className="blog-content">
                <div class="elfsight-app-ca60cc41-a835-48bc-ab3b-783ec64b3caa"></div>
            </div>
            
     
        </div>


    );
}

export default OurBlog;